import React from "react";
import {Helmet} from "react-helmet";

export default function Term(){
    return (
        <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Terms and conditions" />
        <meta property="og:description" content="Terms of use of the Glamorous Trend website." />
        </Helmet>        
        <br /><br />
          <div className="py-6 sm:py-12">
            <div className="ring-1 ring-gray-900/5 sm:mx-auto sm:max-w-lg sm:rounded-lg">
              <div className="mx-auto max-w-md px-4">
                <h2><b>Terms and conditions</b></h2>
                <div className="divide-y divide-gray-300/50">
                  <div className="space-y-6 py-8 text-base leading-7 text-gray-600 dark:text-white">
                    <h5>
                    Please read these terms and conditions carefully before using the 
                    <span className="font-bold text-purple-500"> glamoroustrend.com</span> website operated by 
                    <a className="text-blue-500 hover:text-blue-600 font-bold" href="https://arfusoft.com/" target="_blank" rel="noreferrer"> Arfusoft</a>.
<br/>✤ <b>Conditions of use</b><br/>
Using this website, you certify that you have read and reviewed this Agreement and agree to comply with its terms. If you do not want to be bound by the terms of this Agreement, you are advised to stop using the website accordingly. Arfusoft only grants use and access to this website, its products, and its services to those who have accepted its terms.
<br/>✤ <b>Privacy policy</b><br/>
Before you continue using our website, we advise you to read our  <a className="text-purple-500 hover:text-purple-600 font-bold" href="/policy" >privacy policy</a> regarding our user data collection. It will help you better understand our practices.
<br/>✤ <b>Age restriction</b><br/>
You must be at least 18 (eighteen) years of age before you can use this website. Using this website, you warrant that you are at least 18 years of age and may legally adhere to this Agreement. Arfusoft assumes no responsibility for liabilities related to age misrepresentation.
<br/>✤ <b>Intellectual property</b><br/>
You agree that all materials, products, and services provided on this website are the property of Arfusoft, its affiliates, directors, officers, employees, agents, suppliers, or licensors, including all copyrights, trade secrets, trademarks, patents, and other intellectual property. You also agree that you will not reproduce or redistribute the Arfusoft's intellectual property in any way, including electronic, digital, or new trademark registrations.
You grant Arfusoft a royalty-free and non-exclusive license to display, use, copy, transmit, and broadcast the content you upload and publish. You should contact the company to agree on issues regarding intellectual property claims.
<br/>✤ <b>User accounts</b><br/>
As a website user, you may be asked to register with us and provide private information. You are responsible for ensuring the accuracy of this information, and you are responsible for maintaining the safety and security of your identifying information. You are also responsible for all activities under your account or password.
If there are any possible issues regarding the security of your account on the website, inform us immediately so we may address them accordingly.
We reserve all rights to terminate accounts, edit or remove content, and cancel orders at our sole discretion.
<br/>✤ <b>Applicable law</b><br/>
By using this website, you agree that the laws of the United States, without regard to principles of conflict laws, will govern these terms and conditions or any dispute of any sort that might come between Arfusoft and you or its business partners and associates.
<br/>✤ <b>Disputes</b><br/>
Any dispute related to your use of this website or products you purchase from us shall be arbitrated by state or federal court, and you consent to exclusive jurisdiction and venue of such courts.
<br/>✤ <b>Indemnification</b><br/>
You agree to indemnify Arfusoft and its affiliates and hold Arfusoft harmless against legal claims and demands arising from your use or misuse of our services. We reserve the right to select our legal counsel. 
<br/>✤ <b>Limitation on liability</b><br/>
Arfusoft is not liable for any damages that may occur to you due to your misuse of our website.
Arfusoft reserves the right to edit, modify, and change this Agreement at any time. We shall let our users know of these changes through electronic mail. This Agreement is an understanding between Arfusoft and the user, and this supersedes and replaces all prior agreements regarding using this website.
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
    
        </>
    
      );
}