import React from "react";
import {Helmet} from "react-helmet";
function About() {
    return (
      <>
        <Helmet>
        <meta charSet="utf-8" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="About" />
        <meta property="og:description" content="Why we built the Glamorous Trend website." />
        </Helmet>      
        <div className="py-6 sm:py-12">
          <br /><br />
          <div className="ring-1 ring-gray-900/5 sm:mx-auto sm:max-w-lg sm:rounded-lg">
            <div className="mx-auto max-w-md px-4">
              <h2><b> About </b></h2>
              <div className="divide-y divide-gray-300/50">
                <div className="space-y-6 py-8 text-base leading-7 text-black-500 dark:text-white">
                  <p>
                 At our company, <b>we</b> are all passionate about fashion. 
                 <b>We </b> research the subject extensively and are always eager to know which fashion style is in vogue, where, and why. 
                  So, <b>we </b> created this awesome website to share our findings with the community. 
                  <b> We</b> are not and do not pretend to be experts in the fashion field. 
                  So, <b>we </b> only base our posts on research. <br /> 
                  But who are <b>We </b>?
                  </p>
                  <p> 
                  We, refers to <a className="text-blue-400 hover:text-blue-600 font-bold" href="https://arfusoft.com/" target="_blank" rel="noreferrer">Arfusoft</a> , our software development company that designed and implemented this website.
                </p>
                </div>
              </div>
            </div>
          </div>
        </div>
  
      </>
  
    );
  }
  export default About;