import React from "react";
import ukraine from "../assets/images/ukraineflag.png";


function TopAppBar(){
    return (
      <nav className="w-full py-4 bg-gradient-to-r from-slate-700  to-sky-700  shadow  navbar-sticky">
        <div className="w-full container mx-auto flex flex-wrap items-center justify-between">
          <nav>
            <ul
              className="flex items-center justify-between font-semibold text-sm text-white uppercase no-underline"
            >
              <li>
                <a className="hover:text-gray-200 hover:underline px-4" href="/">Home</a>
              </li>
              <li>
                <div className="">
                  <div className="dropdown-container">
                    <div className="dropdown">
                      <label className="flex cursor-pointer px-0" tabIndex="0"
                        >Categories
                      </label>
                      <div className="dropdown-menu dropdown-menu-bottom-right  w-32  bg-glass dark:bg-backgroundPrimary">
                      <a href="/news" className="btn  btn-ghost text-sm text-base">News</a>
                      <a href="/clothing" className="btn  btn-ghost text-sm text-base">Clothing</a>
                        {/**
                        <a href="/dress" className="btn btn-xs text-xs text-base">Dress</a>
                        <a href="/jeans" className="btn btn-xs text-xs text-base">Jeans</a>
                        <a href="/blouse" className="btn btn-xs text-xs text-base">Blouse</a>
                        <a href="/coat" className="btn btn-xs text-xs text-base">Coat</a>
                       
                        <a href="/swimsuit" className="btn btn-xs text-xs text-base">Swimsuit</a>
                        <a href="/bra" className="btn btn-xs text-xs text-base">Bra</a>
                        <a href="/scarf" className="btn btn-xs text-xs text-base">Scarf</a>
                        <a href="/gloves" className="btn btn-xs text-xs text-base">Gloves</a>
                        <a href="/boots" className="btn btn-xs text-xs text-base">Boots</a>
                        <a href="/socks" className="btn btn-xs text-xs text-base">Socks</a>
                        
                        <a href="/jumper" className="btn btn-xs text-xs text-base">Jumper</a>

                        <a href="/shorts" className="btn btn-xs text-xs text-base">Shorts</a>
                        <a href="/skirt" className="btn btn-xs text-xs text-base">Skirt</a>
                        <a href="/suit" className="btn btn-xs text-xs text-base">Suit</a>


                        <a href="/leggings" className="btn btn-xs text-xs text-base">Leggings</a>
                        <a href="/pajamas" className="btn btn-xs text-xs text-base">Pajamas</a>

                        <a href="/vest" className="btn btn-xs text-xs text-base">Vest</a>
                        <a href="/activewear" className="btn btn-xs text-xs text-base">Activewear</a>
                        <a href="/caftan" className="btn btn-xs text-xs text-base">Caftan</a>
                        <a href="/cap" className="btn btn-xs text-xs text-base">Cap</a>
                        */} 
                        <a href="/shoes" className="btn  btn-ghost text-sm text-base">Shoes</a>
                        <a href="/bags" className="btn  btn-ghost text-sm text-base">Bags</a>
                        <a href="/jewelry" className="btn  btn-ghost text-sm text-base">Jewelry</a>
                        <a href="/watches" className="btn  btn-ghost text-sm text-base">Watches</a>
                        <a href="/skincare" className="btn  btn-ghost text-sm text-base">Skincare</a>
                        <a href="/other" className="btn  btn-ghost text-sm text-base">Other</a>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <a className="hover:text-gray-200 hover:underline px-4" href="/about">About</a>
              </li>
            </ul>
          </nav>
  
          <div className="flex items-center font-semibold text-sm text-white uppercase no-underline text-white pr-2">
            <ul>
            <li>
                <img src={ukraine} width={32} height={32} alt="ukraine will win"></img>
              </li>
            </ul>

            {/*<a href="#" className=""><ion-icon name="logo-facebook"></ion-icon></a>&nbsp;
            <a href="#" className=""><ion-icon name="logo-tumblr"></ion-icon></a>*/}
          </div>
        </div>
      </nav>
  
    );
  }


  export default TopAppBar;